.pageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: whitesmoke;
    overflow-x: hidden;
}

.main {
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
}

@media (max-width:993px) {
    .pageContainer {
        height: calc(100vh - 65px);
        width: 100vw;
        display: block;
    }

    .main {
        width: 100vw;
        margin-left: 0;
    }
}

/* Header styling */
.header {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-bottom: 1rem;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

.activeTab {
    border-bottom: 3px solid #4eac6d;
    font-weight: bold;
    color: #4eac6d;
    transition: all 0.3s ease-in-out;
}

.inactiveTab {
    color: gray;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.inactiveTab:hover {
    color: black;
    font-weight: bold;
}

.searchBar {
    display: flex;
    align-items: baseline;
    justify-content: center;
    max-width:400px;
}

.datePickerContainer {
    top: 35px;
    right: 0;
    width: 230px;
    z-index: 10;
}

.navTabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.navTabs::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari */
}

.navTabs li {
    flex-shrink: 0; /* Prevent items from shrinking */
}
