.scrollContainer {
    padding: 1rem;
    overflow-y: auto;
    background-color: white;
    flex-grow: 1;
    border-radius: .5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.reportCard {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.collapsibleContent {
    background-color: #f9f9f9;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

.customerDetail {
    margin-bottom: 0.75rem;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 0.5rem;
}

.customerDetail:last-child {
    border-bottom: none;
}