.scrollContainer {
    flex-grow: 1 ;
    overflow-y: auto; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: .5rem;
    height: 75vh;
    width: 100%;
}


.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #ddd;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.iconWrapper:hover {
    background-color: #f0f0f0;
}

.tableContainer {
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; 
}

.tableContainer:-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari */
}


.rowWhite {
    background-color: #fff;
    /* White background for even rows */
}

.rowGray {
    background-color: #f9f9f9;
    /* Light gray background for odd rows */
}

.table td {
    padding: 12px 8px;
    border: none;
    /* Remove borders */
}

.table th {
    border: none;
    /* Remove borders */
}
:global(.react-datepicker-popper) {
    z-index: 250;
}




.datePickerWrapper {
    z-index: 9999;
    /* Ensure it's on top of other elements */
    top: 100%;
    /* Position the DatePicker below the input field */
    left: 0;
    /* Align the DatePicker with the left edge of the input field */
    right: 0;
    /* Optional: if you want to restrict its width to input field */
}


.paginationPageInfo {
    font-size: 14px;
}

.filterRemoveBtn {
    color: white;
    background-color: rgb(103, 39, 39);
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    

}

.dot{
    background-color: #007bff;
    width: .6rem;
    height: .6rem;
    border-radius: 1rem;
    top: -2px;
    right: 1px;
    position: absolute;
}

.goToTop{
bottom: 3vh;
right: calc(50vw - 75px),
}

@media (max-width:993px) {
    .goToTop{
        bottom: calc(3vh + 65px);
        right: '50vw',

    }
    .reportContainer {
        margin-left: 0;
        padding: 0;
        height: 95vh;
    }
   
}



/* Default Filter Row */
.filterRow {
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content:space-evenly;
    background-color: whitesmoke ;
    border-radius: .5rem;
    margin-bottom: .5rem;
}


/* Mobile Filter Row (Initially Hidden) */
@media (max-width: 768px) {
    .filterRow {
        position: absolute;
        top: 60px;
        /* Adjust to avoid overlapping */
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        background: white;
        z-index: 100;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        border-radius: 8px;
        display: none;
        /* Hidden by default */
    }

    .showFilter {
        display: block;
        z-index: 200;
        /* Show when the filter is active */
    }

    .totalLengthContainer{
        width: 100%;
    }
}

/* Filter Items (Responsive Width) */
.filterItem {

    margin: 0.5rem;
}

/* Adjust Filter Width for Medium Screens */
@media (max-width: 768px) {
    .filterItem {
        flex: 1 1 calc(50% - 1rem);
    }
}

/* Adjust for Small Screens */
@media (max-width: 480px) {
    .filterItem {
        flex: 1 1 100%;
    }
}