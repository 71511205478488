/* labels.module.css */
.dropdownContainer {
    margin: 10px 0;
}

.dropdown-toggle {
    width: 100%;
    text-align: center;
    background-color: #469b62
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
    /* Light hover effect */
}

.scrollableDropdown{
    max-height: 200px; 
    overflow-y: auto;
}