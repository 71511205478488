.pageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: whitesmoke;
}

.main {
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
}

@media (max-width:993px) {
    .pageContainer {
        height: calc(100vh - 65px);
        width: 100vw;
        display: block;
    }

    .main {
        width: 100vw;
        margin-left: 0;
    }
}

.keywordContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.keywordBadge {
    background-color: #f1f1f1;
    /* Light gray background */
    color: black;
    /* Black text */
    padding: 4px 8px;
    border-radius: 20px;
    /* Pill shape */
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #d1d1d1;
    /* Slight border for contrast */
}

.removeBtn {
    background: transparent;
    border: none;
    color: black;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
}

.keywordInputContainer {
    position: relative;
    width: 100%;
}

.keywordInput {
    width: 100%;
    padding: 8px;
}

.suggestionList {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    list-style: none;
    padding: 0;
    margin-top: 5px;
    z-index: 1000;
}

.suggestionItem {
    padding: 8px;
    cursor: pointer;
}

.suggestionItem:hover {
    background: #f1f1f1;
}

.keywordInput,
.addBtn {
    width: 100%;
}


@media (min-width:768px) {
    .addBtn {
        width: 25%;
    }

}

.settingsCard{
    flex-grow: 1;
    margin-bottom: 0;
    margin-top: 0;
}