.overviewCard {
  transition: transform 0.3s ease-in-out;
}

.overviewCard:hover {
  transform: scale(1.05);
}

.disabledCard {
  background-color: rgba(211, 211, 211, 0.944);
  color: white;
  text-shadow:1px 1px 2px black;
  cursor: not-allowed;
  transform: none;
}
.disabledCard:hover{
  transform: none;
}
