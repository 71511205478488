.campaignForm {
    background-color: whitesmoke;
    height: 100%;
    overflow-y: auto;
    padding: .5rem;
    border-radius: .5rem;
    width: 100%;

}

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
}

/* Hide default checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

/* The slider's handle */
.slider::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;

}

/* Checked state */
input:checked+.slider {
    background-color: #2196f3;

}

input:checked+.slider::before {
    transform: translateX(22px);
}

.progressbar {
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    counter-reset: step;
    /* CSS counters for numbering steps */
}

.progressbar li {
    list-style: none;
    color: #000;
    text-transform: uppercase;
    font-size: 13px;
    width: auto;
    text-align: center;
    position: relative;
    font-weight: 600;
    flex: 1;
    /* Equal width for all steps */
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    /* Increased for better visibility */
    color: #fff;
    background: #ccc;
    /* Default gray background for steps */
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Centers the number */
}

.progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    background: #ccc;
    /* Default gray connector */
    position: absolute;
    left: 50%;
    top: 15px;
    /* Align with the center of the circles */
    z-index: -1;
    transform: translateX(-50%);
}

.progressbar li:first-child:after {
    content: none;
    /* Remove connector for the first step */
}

.progressbar li.active:before {
    background: #27ae60;
    /* Green background for active steps */
}

.progressbar li.active:after {
    background: #27ae60;
    /* Green connector for active steps */
}

@media (min-width:768px) {
    .campaignForm{
        width: 50%;
    }
    
}