.dropdownMenu {
    position: absolute;
    top: 0px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 150px;
    z-index: 10;
}

.userMessage{
    right:-150px;

}
.agentMessage{
    right: 25px;

}

.dropdownMenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdownMenu li {
    padding: 10px;
    cursor: pointer;
    transition: background 0.2s;
}

.dropdownMenu li:hover {
    background: #f0f0f0;
}

