.pageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: whitesmoke;
}

.agentDetailPage {
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
}

@media (max-width:993px) {
    .pageContainer {
        height: calc(100vh - 65px);
        width: 100vw;
        display: block;
    }
    .agentDetailPage {
        width: 100vw;
        margin-left: 0;

    }

}

.card {
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
}

.profilePic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #007bff;
}

.activityList {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 35vh;
    overflow-y:auto ;
}

.activityList li {
    background: #f8f9fa;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

}

/* Calendar-specific styles for a modern look */
/* Remove default borders & add modern look to the entire calendar container */
.reactCalendar {
    background-color: #fff !important;
    border: none !important;
    /* Remove outer border */
    border-radius: 10px !important;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    /* Soft shadow */
    padding: 1rem !important;
    width: 100% !important;
    height: 100% !important;
    /* Fill parent if you want a taller calendar */
    display: flex !important;
    flex-direction: column !important;
}

/* This container holds the main calendar view(s) */
.reactCalendar .react-calendar__viewContainer {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
}

/* Navigation bar (month/year selectors) spacing & style */
.reactCalendar .react-calendar__navigation {
    margin-bottom: 1rem !important;
}

.reactCalendar .react-calendar__navigation button {
    color: #007bff !important;
    /* Navigation arrows color */
    background: transparent !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 0.4rem 0.8rem !important;
    transition: background-color 0.2s ease;
}

.reactCalendar .react-calendar__navigation button:hover {
    background-color: #e2e6ea !important;
}

/* Remove tile borders, add subtle rounding & hover effect */
.reactCalendar .react-calendar__tile {
    border: none !important;
    border-radius: 6px !important;
    margin: 2px !important;
    transition: all 0.2s ease-in-out;
}

.reactCalendar .react-calendar__tile:hover {
    background-color: #e2e6ea !important;
}

/* Active day tile (when clicked or selected) */
.reactCalendar .react-calendar__tile--active {
    background-color: #007bff !important;
    border-radius: 6px !important;
    color: #fff !important;
}

/* Remove default highlight for today's date if you want a simpler look */
.reactCalendar .react-calendar__tile--now {
    background: transparent !important;
    border-radius: 6px !important;
    font-weight: bold;
    color: #007bff;
}

/* If you want a subtle highlight for today, use something like: 
  .reactCalendar .react-calendar__tile--now {
    background: #f3f8ff !important;
    border-radius: 6px !important;
    color: #007bff !important;
    font-weight: bold;
  }
  */

/* Custom text for holiday/active days */
.holidayText {
    color: red;
    font-weight: bold;
    font-size: 0.8rem;
}

.activeText {
    color: green;
    font-size: 0.8rem;
}

/* Cursor pointer for the calendar icon */
.cursor-pointer {
    cursor: pointer;
}

