.replyContainer {
    position: relative;
    display: flex;
    align-items: flex-start;
    background-color:whitesmoke;
    padding: .5rem;
    padding-right: 0;
    border-radius: 8px 8px 0 0;
    width: 100%;
    color: black;
}

.userMessage{
    border-left: 4px solid #255cd3;
    
}

.agentMessage{
    border-left: 4px solid #25D366;

}

.replyText {
    font-size: 14px;
    color: #757679;
    margin-top: 2px;

}

.userName {
    font-weight: 500;
    color: #255cd3;
}
.agentName {
    font-weight: 500;
    color: #25D366;
}

.closeButton {
    background: none;
    border: none;
    color: #b0b3b8;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.2s;
}


.breakWord {
    word-wrap: break-word;
    overflow-wrap: break-word;
}