.broadcastContainer {
    background-color: #f9f9f9;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;

}

.main {
    width: 90%;
    padding: 20px;
    /* Add padding for spacing inside the container */
    background-color: #ffffff;
    /* Set a light background color */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Soft shadow effect */
    border: 1px solid #e0e0e0;
    /* Optional: Add a light border */
    height: 100vh;

}


.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #ddd;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.iconWrapper:hover {
    background-color: #f0f0f0;
}

.tableContainer {
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
    overflow-x: auto;
    scrollbar-width:thin;
    -ms-overflow-style: none; 
}
/* .tableContainer:-webkit-scrollbar {
    display: none; 
} */

@media (max-width:992px) {
    .broadcastContainer {

        flex-direction: column;
    }

    .main {
        width: 100%;
        margin: 0 auto;
        /* margin-top: .5rem; */
        border-radius: 0;
        height: calc(100vh - 65px);
    }

}

.table {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
}



.rowWhite {
    background-color: #fff;
    /* White background for even rows */
}

.rowGray {
    background-color: #f9f9f9;
    /* Light gray background for odd rows */
}

.table td {
    padding: 12px 8px;
    border: none;
    /* Remove borders */
}

.table th {
    border: none;
    /* Remove borders */
}
:global(.react-datepicker-popper) {
    z-index: 250;
}




.datePickerWrapper {
    z-index: 9999;
    /* Ensure it's on top of other elements */
    top: 100%;
    /* Position the DatePicker below the input field */
    left: 0;
    /* Align the DatePicker with the left edge of the input field */
    right: 0;
    /* Optional: if you want to restrict its width to input field */
}


.paginationPageInfo {
    font-size: 14px;
}

.filterRemoveBtn {
    color: white;
    background-color: rgb(103, 39, 39);
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    

}

.customCheckbox {
    appearance: none;
    /* Remove default checkbox styling */
    width: 1.2rem;
    /* Size of the checkbox */
    height: 1.2rem;
    background-color: #e0e0e0;
    /* Default gray background */
    border: 2px solid #ccc;
    /* Light gray border */
    border-radius: 50%;
    /* Makes the checkbox round */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    
}

.customCheckbox:checked {
    background-color: #007bff;
    /* Blue background when checked */
    border-color: #007bff;
    /* Match border with background */
    position: relative;
   
}

.dot{
    background-color: #007bff;
    width: .6rem;
    height: .6rem;
    border-radius: 1rem;
    top: -2px;
    right: 1px;
    position: absolute;
}

.goToTop{
bottom: 3vh;
right: calc(50vw - 75px),
}

@media (max-width:993px) {
    .goToTop{
        bottom: calc(3vh + 65px);
        right: '50vw',

    }
}

.customCheckbox:checked::after {
    content: '✔';
    /* Tick mark */
    color: white;
    /* White tick */
    font-size: 14px;
    /* Adjust tick size */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the tick */
}

.customCheckbox:hover {
    border-color: #0056b3;
    /* Darker blue on hover */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Subtle glow on hover */
}

/* Default Filter Row */
.filterRow {
    width: 100%;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: last baseline;
    margin-bottom: .5rem;
    background-color: lightgray;
    border-radius: .5rem;
}

.broadcastbtnContainer{
    width: 25%;
    display: flex;
    justify-content: end;
    align-items: center;
}

/* Mobile Filter Row (Initially Hidden) */
@media (max-width: 768px) {
    .filterRow {
        position: absolute;
        top: 60px;
        /* Adjust to avoid overlapping */
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        background: white;
        z-index: 100;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        border-radius: 8px;
        display: none;
        /* Hidden by default */
    }

    .showFilter {
        display: block;
        z-index: 200;
        /* Show when the filter is active */
    }

    .broadcastbtnContainer{
        width: 100%;
        justify-content: space-between;
    }
    .totalLengthContainer{
        width: 100%;
    }
}

/* Filter Items (Responsive Width) */
.filterItem {

    margin: 0.5rem;
}

/* Adjust Filter Width for Medium Screens */
@media (max-width: 768px) {
    .filterItem {
        flex: 1 1 calc(50% - 1rem);
    }
}

/* Adjust for Small Screens */
@media (max-width: 480px) {
    .filterItem {
        flex: 1 1 100%;
    }
}