.reportContainer {
    margin: 0 auto;
    /* padding: 1rem; */
    overflow: hidden;
    flex-grow: 1;
    width: 100%;
}

.reportCard {
    border-radius: .5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    background-color: white;
}

.reportCard:last-child{
    margin-bottom: 0;
}

.scrollContainer {
    flex: 1;
    overflow-y: auto; 
    padding-top: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: .5rem;
}
:global(.react-datepicker-wrapper) {
    width: 100% !important;
    display: flex;
    justify-content: center;
}

:global(.react-datepicker-wrapper input) {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
}


@media (max-width:993px) {
    .reportContainer {
        margin-left: 0;
        height: 95vh;
    }
   
}

