/* Profile Container */
.profileContainer {
    height: 100vh;
    background: linear-gradient(to bottom, #f8f9fa, #eef1f5);
    display: flex;
    flex-direction: column;
}

/* Header */
.header {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    padding: 14px;
    border-bottom: 1px solid #ddd;
}

/* Profile Image Section */
.profileImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 20px 0;
}

.profileImage {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

/* Scrollable Content */
.scrollableContent {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 2rem;
    max-height: calc(100vh - 180px);
}

/* Sections */
.section {
    padding: 16px;
}

.sectionContent {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
}

.sectionContent:hover {
    background: rgba(255, 255, 255, 0.9);
}

/* Info Item */
.infoItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    transition: transform 0.2s ease-in-out;
}

.infoItem:hover {
    transform: translateX(5px);
}

/* Icon Wrapper */
.iconWrapper {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(238, 241, 245, 0.8);
    border-radius: 50%;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
    margin-right: 12px;
}

.iconWrapper i {
    font-size: 20px;
}

/* Responsive Styles */
@media (max-width: 993px) {
    .scrollableContent {
        max-height: calc(100vh - 200px);
    }

    .profileImage {
        width: 90px;
        height: 90px;
    }
}

.breakButton {
    background: #f8c471;
    border: none;
    padding: 10px 14px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #6d4c41;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
}

.breakButton i {
    margin-right: 6px;
    font-size: 18px;
}

.breakButton:hover {
    background: #e59866;
}